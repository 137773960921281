import { useHistory, useLocation } from 'react-router-dom';
import './index.less';
import { useMemo } from 'react';
import useMetiersRendering from 'Hooks/use-metiers-rendering';
import { RawJob } from 'Models/MetierDefinitions';
import Button from 'Components/button';
import Copydeck from 'i18n/Copydeck';
import BlueCard from 'Components/blue-card';
import TemoignageCard from 'Components/temoignage-card';
import { CONCESSIONS_URL, METIER_URL } from 'Models/Constants';
import { useWindowDimensions } from 'Hooks';
import Content from 'Components/Content';
import NotFound from 'Routes/not-found';
import { TemoignagesDefinition } from 'Models/TemoignagesDefinition';
import { useTranslation } from 'react-i18next';

const MetierDetails = () => {
    const { t } = useTranslation();
    const location = useLocation<{ categories: string[] }>();
    const history = useHistory<{ categories: string[] }>();
    const { windowWidth } = useWindowDimensions();
    const { allMetiers } = useMetiersRendering();

    const metier = useMemo<RawJob | null>(
        () =>
            allMetiers.find((metier) => t(`${metier.to}`) === location.pathname.split('/').pop()) ||
            null,
        [allMetiers, location.pathname, t]
    );

    const testimony = useMemo(
        () => TemoignagesDefinition.find((temoignage) => temoignage.id === metier?.testimonyId),
        [metier?.testimonyId]
    );

    return (
        <div className="MetierDetails">
            <Content className="MetierDetails__content">
                <div className="MetierDetails__backButtonContainer">
                    <Button
                        type="text"
                        text={Copydeck.metierDetailsMetierDetailsBackButton}
                        onClick={() =>
                            history.push({
                                pathname: METIER_URL,
                                state: location.state,
                            })
                        }
                        leftIcon="ChevronLeftIcon"
                    />
                </div>
                {metier ? (
                    <div className="MetierDetails__metier">
                        <div className="MetierDetails__leftContainer">
                            {windowWidth >= 900 && (
                                <img
                                    src={metier.image.large}
                                    alt={metier.title}
                                    className="MetierDetails__image"
                                />
                            )}
                            <BlueCard className="MetierDetails__ctaContainer">
                                <h2 className="text-display-medium-extenda MetierDetails__ctaTitle">
                                    {Copydeck.metierDetailsMetierDetailsCtaTitle}
                                </h2>
                                <Button
                                    type="red"
                                    text={
                                        windowWidth >= 900
                                            ? Copydeck.metierDetailsMetierDetailsCta
                                            : Copydeck.metierDetailsMetierDetailsCtaMobile
                                    }
                                    onClick={() => history.push(CONCESSIONS_URL)}
                                    withRightArrow
                                    width="full"
                                    height="large"
                                />
                            </BlueCard>
                        </div>
                        <div className="MetierDetails__rightContainer">
                            {windowWidth < 900 && (
                                <img
                                    src={metier.image.large}
                                    alt={metier.title}
                                    className="MetierDetails__image"
                                />
                            )}
                            <h1 className="text-body-large MetierDetails__title">{metier.title}</h1>
                            <div className="text-body-large MetierDetails__descriptionP1">
                                {metier.description.p1}
                            </div>
                            <div className="text-body-large-bold MetierDetails__subtitle">
                                {metier.description.h2}
                            </div>
                            <ul className="MetierDetails__tasks">
                                {metier.description.h3.map((task) => (
                                    <li key={task} className="MetierDetails__tasksItem">
                                        <h3 className="text-body-large MetierDetails__task">
                                            {task}
                                        </h3>
                                    </li>
                                ))}
                            </ul>
                            <div
                                className="text-body-large MetierDetails__descriptionP2"
                                dangerouslySetInnerHTML={{
                                    __html: metier.description.p2,
                                }}
                            />
                            {testimony && (
                                <>
                                    <hr className="MetierDetails__separator" />
                                    <div className="MetierDetails__temoignageSection">
                                        <h2
                                            className="MetierDetails__temoignageSectionTitle"
                                            dangerouslySetInnerHTML={{
                                                __html: Copydeck.industrieIndustrieTemoignagesTitle,
                                            }}
                                        />
                                        {testimony && (
                                            <TemoignageCard
                                                fullWidth
                                                image={testimony.image}
                                                quote={Copydeck[testimony.quote]}
                                                description={Copydeck[testimony.description]}
                                                jobTitle={Copydeck[testimony.jobTitle]}
                                                name={Copydeck[testimony.name]}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <NotFound />
                )}
            </Content>
        </div>
    );
};

export default MetierDetails;
